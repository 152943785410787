<section class="section-footer">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-4">
				<div class="widget-a">
					<div class="w-body-a" *ngIf="this.footerContact != null">
						<h4>{{ 'COMPONENTS.FOOTER.CONTACTS.TITLE' | translate}}</h4>
                        <p class="w-text-a color-text-a">
                            <span *ngIf="this.footerContact.telefono != null"><span class='fa fa-phone'></span>&nbsp;{{this.footerContact.telefono}}</span><br/>
                            <span *ngIf="this.footerContact.fax != null"><span class='fa fa-fax'></span>&nbsp;{{this.footerContact.fax}}<br /></span>
                            <span *ngIf="this.footerContact.email != null"><span class='fas fa-at'></span>&nbsp;<a [href]="'mailto:'+this.footerContact.email">{{this.footerContact.email}}</a></span>
                        </p>
                        <p class="w-text-a color-text-a">
                            <span>
                                <b>{{this.footerContact.nome}}</b><br>
                                {{this.footerContact.indirizzo}}, {{this.footerContact.civico}}<br>
                                {{this.footerContact.cap}} {{this.footerContact.citta}}, {{this.footerContact.paese}}<br>
                                {{this.footerContact.note}}
                            </span>
                        </p>
					</div>
					<div class="socials-a">
						<ul class="list-inline">
							<li class="list-inline-item"><a target="_blank" href="https://www.facebook.com/NaarTourOperator"><i class="fab fa-facebook" aria-hidden="true"></i></a></li>
							<!-- <li class="list-inline-item"><a target="_blank" href="https://twitter.com/NaarTO_"><i class="fab fa-twitter" aria-hidden="true"></i></a></li> -->
							<li class="list-inline-item"><a target="_blank" href="https://www.linkedin.com/company/naar-tour-operator"><i class="fab fa-linkedin" aria-hidden="true"></i></a></li>
							<li class="list-inline-item"><a target="_blank" href="https://www.instagram.com/naartouroperator_/"><i class="fab fa-instagram" aria-hidden="true"></i></a></li>
							<li class="list-inline-item"><a target="_blank" href="https://www.youtube.com/channel/UC-pOpj9aeKf0eM74OUtbKfg"><i class="fab fa-youtube" aria-hidden="true"></i></a></li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-4 section-md-t3">
				<div class="widget-a">
					<!-- <div class="w-header-a">
						<h3 class="w-title-a text-brand">{{ 'COMPONENTS.FOOTER.TITLES.INFO-LINK' | translate}}</h3>
					</div> -->
						<div class="w-body-a">
							<ul class="list-unstyled">
								<li class="item-list-a" *ngFor="let item of getFooterColumn(COLUMN_GENERAL)">
									<i class="fa fa-angle-right"></i>&nbsp;
									<a [routerLink]="getArticleLink(item.entityId)">{{ getArticleName(item.name) }}</a>
								</li>
							</ul>
						</div>
					</div>
			</div>
			<div class="col-sm-12 col-md-4 section-md-t3">
				<div class="widget-a">
					<div class="w-body-a">
						<ul class="list-unstyled">
							<li class="item-list-a" *ngFor="let item of getFooterColumn(COLUMN_INFO)">
								<i class="fa fa-angle-right"></i>&nbsp;
								<ng-container *ngIf="isPrivacyLink(item);else noPrivacy">
									<a [routerLink]="getArticleLink(item.entityId)" target="_blank">{{ getArticleName(item.name) }}</a>
								</ng-container>
								<ng-template #noPrivacy>
									<a [routerLink]="getArticleLink(item.entityId)">{{ getArticleName(item.name) }}</a>
								</ng-template>
							</li>
						</ul>
					</div>
					<!-- <div class="w-header-a">
						<h3 class="w-title-a text-brand">{{ 'COMPONENTS.FOOTER.TITLES.PARTNERSHIP' | translate}}</h3>
					</div> -->
					<!-- <div class="w-body-a">
						<ul class="list-unstyled">
							<li class="item-list-a"><i class="fa fa-angle-right"></i> <a target="_blank" href="https://www.naar.com/landing/dubai/index.html">Dubai</a></li>
							<li class="item-list-a"><i class="fa fa-angle-right"></i> <a target="_blank" href="https://www.naar.com/landing/maldives">Maldive</a></li>
							<li class="item-list-a"><i class="fa fa-angle-right"></i> <a target="_blank" href="https://www.naar.com/landing/mauritius">Mauritius</a></li>
							<li class="item-list-a"><i class="fa fa-angle-right"></i> <a target="_blank" href="https://www.naar.com/landing/sandals/index.html">Sandals</a></li>
							<li class="item-list-a"><i class="fa fa-angle-right"></i> <a target="_blank" href="https://www.naar.com/landing/seychelles/index.html">Seychelles</a></li>
						</ul>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</section>
<footer>
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<!-- <nav class="nav-footer">
					<ul class="list-inline">
						<li class="list-inline-item" *ngFor="let item of menuVoices">
							<a  routerLinkActive="active"
								[routerLinkActiveOptions]="{exact: false}"
								[routerLink]="item.url">{{ item.nome }}</a>
						</li>
					</ul>
				</nav>
				<div class="socials-a">
					<ul class="list-inline">
						<li class="list-inline-item"><a href="https://www.facebook.com/NaarTourOperator" target="_blank"><i class="fab fa-facebook" aria-hidden="true"></i></a></li>
						<li class="list-inline-item"><a href="https://twitter.com/NaarTO_" target="_blank"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
						<li class="list-inline-item"><a href="https://www.instagram.com/naartouroperator_/" target="_blank"><i class="fab fa-instagram" aria-hidden="true"></i></a></li>
						<li class="list-inline-item"><a href="https://www.youtube.com/channel/UC-pOpj9aeKf0eM74OUtbKfg" target="_blank"><i class="fab fa-youtube" aria-hidden="true"></i></a></li>
					</ul>
				</div> -->
				<div class="copyright-footer">
					<p class="copyright color-text-a">
						<sub>&copy; Copyright</sub> &nbsp; <span class="color-a" [innerHtml]="'COMPONENTS.FOOTER.COPYRIGHT.NAME' | translate"></span> &nbsp; <sub>{{ 'COMPONENTS.FOOTER.COPYRIGHT.ALL-RIGHT' | translate}}</sub>
					</p>
				</div>
			</div>
		</div>
	</div>
</footer>
