import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import { Destination } from 'nr-common';
import { CommonService } from '../../../services/common.service';
import { UrlService } from '../../../services/url.service';

const MAX_DESCR_LEN = 200;

@Component({
	selector: 'nr-destination-card',
	templateUrl: './destination-card.component.html',
	styleUrls: ['./destination-card.component.scss']
})
export class DestinationCardComponent implements OnInit {
	get invertedColor(): string {
		return this.commonService.invertColor(this.destination.gaBgColor, this.commonService.INVERT_ONLY_BLACK_AND_WHITE);
	}
	@Input() destination: Destination;

	constructor(
		public urlService: UrlService,
		public commonService: CommonService
	) { }

	ngOnInit(): void {
	}

	endSoftly(v: string): string {
		if (v && v.length > MAX_DESCR_LEN) {
			v = v.substring(0, MAX_DESCR_LEN);
			const lastBlank = v.lastIndexOf(' ');
			v = v.substring(0, lastBlank) + '...';
		}
		return v;
	}

	getIcon(iconGliph: string): string {
		if (!iconGliph) {
			iconGliph = 'icon-people';
		}
		return iconGliph;
	}

	getColor(bgColor: string): string {
        if (bgColor == null) return null;
		if (!bgColor.startsWith("#")) {
			bgColor = "#" + bgColor;
		}
		return bgColor;
	}

	transformName(name: string): string {
		return this.commonService.capitalizeAllWords(name);
	}
}
