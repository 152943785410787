import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EOperatore, MenuData, UserService, WebsiteLanguage} from 'nr-common';
import {ApiService} from '../../../services/api.service';
import { WebsiteService } from '../../../services/website.service';
import { Subscription } from 'rxjs';

//const MIN_SCROLL_THRESHOLD = 50;

@Component({
	selector: 'nr-info-strip',
	templateUrl: './info-strip.component.html',
	styleUrls: ['./info-strip.component.scss'],
})
export class InfoStripComponent implements OnInit, OnDestroy {
	public hide: boolean;
	public menuVoices: MenuData[];

    //public EOperatore = EOperatore;
    //public currentOperator: EOperatore;

    public availableLanguages = [];

    private subscription = new Subscription();

	constructor(
		private apiService: ApiService,
		public translate: TranslateService,
		public user: UserService,
        public websiteService: WebsiteService
	) {
	}

	ngOnInit(): void {

        this.availableLanguages = this.websiteService.currentWebsite.availableLanguages;
        
        /* this.subscription.add(
            this.websiteService.currentOperator.subscribe((operator: EOperatore) => {
                this.currentOperator = operator;

                this.loadInfoStripContent();
            })
        ); */

        this.loadInfoStripContent();
	}

    private loadInfoStripContent() { 
        this.menuVoices = this.websiteService.getInfostrip();        
        /* this.subscription.add(
            this.apiService.getInfostrip().subscribe((data: MenuData[]) => {
                this.menuVoices = data;
            })
        ); */
    }

    /* public setOperator(op: EOperatore, e: Event) {
        this.websiteService.setOperator(op, true);
        e?.preventDefault();
    } */

    public changeLanguage(language: WebsiteLanguage, event) {
        event.preventDefault();
        
        this.websiteService.setLanguage(language);
    }

    public getLaguageName(language: WebsiteLanguage): string {
        return this.translate.instant("ENUMS.ELingua." + language.linguaID);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
