import {Injectable} from '@angular/core';
import {BackendService, Banner, BaseDataService, ELingua, GetSiteBannersResponse, ISiteRequest} from 'nr-common';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {WebsiteService} from './website.service';
// import { HttpClient } from '@angular/common/http';

/**
 * SERVIZIO PER LA GESTIONE DEI BANNER della HOMEPAGE
 * --------------------------------------------------------------
 * Per abbattere il numero di chiamate al server.
 * Mantiene in cache locale:
 *	- l'insieme dei banner
 */
const PLURAL_ENDPOINT = 'cms/site/Banners';

@Injectable()
export class BannerService {
	// tslint:disable-next-line:variable-name
	private cache_banners$: Observable<Banner[]> = null;
    private bannerLanguage: ELingua = ELingua.Italiano;

	public get hasBanners(): boolean {
		let result = false;
		if (this.cache_banners$ != null) {
			this.cache_banners$.subscribe((data) => {
				result = data.length > 0;
			});
		}
		return result;
	}

	constructor(
		public website: WebsiteService,
		private backend: BackendService,
		private baseData: BaseDataService
	) {
        this.bannerLanguage = this.baseData.getLingua();
	}

	public getBanners() {
		if (this.cache_banners$ == null || this.bannerLanguage != this.baseData.getLingua()) {
			try {
                this.bannerLanguage = this.baseData.getLingua();
                const siteRequest: ISiteRequest = {
					siteID: this.website.currentWebsite.id,
                    lingua: this.bannerLanguage,
				};
				this.cache_banners$ = this.backend
					.call(PLURAL_ENDPOINT, siteRequest)
					.pipe(
						map((data: GetSiteBannersResponse) => {
							return data.banners;
						}),
						shareReplay(1)
					);
			} catch (error) {
				console.error(error);
			}
		}
		return this.cache_banners$;
	}

	getBannerById(id: number) {}
}
