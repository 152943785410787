import {Component, Inject, OnDestroy, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {WebsiteService} from '../../services/website.service';
import {BasePageComponent} from '../../shared/components/base-page/base-page.component';
import {BannerService} from '../../services/banner.service';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {Banner, EBannerType, EOperatore, ETipoEntita, Languages, PageDataGeneric, UserService} from 'nr-common';
import {UrlService} from '../../services/url.service';
import {Slide} from '../../shared/models/slide';
import {CommonService} from '../../services/common.service';
import {DOCUMENT} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
	selector: "nr-homepage",
	templateUrl: "./homepage.component.html",
	styleUrls: ["./homepage.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class HomepageComponent extends BasePageComponent implements OnInit, OnDestroy {
	public banners: Banner[];
    //public widgets: PageDataGeneric[];
    
	public readonly CONTENT_DISPLAY_POSITION_DEFAULT = 0;
	public readonly CONTENT_DISPLAY_POSITION_MIDDLE  = 10;
	public readonly CONTENT_DISPLAY_POSITION_BOTTOM  = 100;

	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow:
			'<div class="nav-btn next-slide nav-btn-middle"><i class="fa fa-chevron-right"></i></div>',
		prevArrow:
			'<div class="nav-btn prev-slide nav-btn-middle"><i class="fa fa-chevron-left"></i></div>',
		dots: false,
		infinite: true,
		swipe: false,
		responsive: [
			{
				breakpoint: 1140, // 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					// dots: true
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	public slides: Slide[] = [];

	public get hasSlides(): boolean {
		return this.slides.length > 0;
	}

    private subs = new Subscription();
    private bannerSub = new Subscription();

	// Rimuove dal DOM della pagina lo script per i cookie dinamici
	#dinamicCookieScriptID = "CookieDeclaration";

	constructor(
		apiService: ApiService,
		webSiteService: WebsiteService,
		titleService: Title,
		public translateService: TranslateService,
		public bannerService: BannerService,
		public urlService: UrlService,
		public commonService: CommonService,
		private renderer2: Renderer2,
		@Inject(DOCUMENT) private _document: Document,
		router: ActivatedRoute,
		translate: TranslateService,
		user: UserService,
		api: ApiService
	) {
		super(apiService, webSiteService, titleService, translateService);
		this.pageName = "home";
	}

	ngOnInit(): void {
		super.ngOnInit();

		// Carico tutti i banner disponibili
        this.subs.add(
            this.translateService.onLangChange.subscribe(() => {
                this.loadPageData();
            })
        );
        this.loadPageData();
        
        /* // Attendo che i dati della pagina siano caricati
        this.subs.add(
            this.isLoading.subscribe(isLoading => {
                if (!isLoading) {
                    this.widgets = this.pageData?.elencoDati?.filter((f) => f.entityType == ETipoEntita.WidgetOffertaCard || f.entityType == ETipoEntita.WidgetServizioCard);
                }
            })
        ); */
	}

    private loadPageData() { 
        if (!this.bannerSub.closed) {
            this.bannerSub.unsubscribe();
        }
        this.bannerSub = this.bannerService.getBanners().subscribe((data) => {
            if (data) {
                this.banners = data.sort((a, b) => a.ordine - b.ordine);
            } else {
                this.banners = [];
            }
            this.loadSlides();
            this.bannerSub.unsubscribe();
        });
    }

	/**
	 * Popolo la lista delle immagini della pagina per il carousel usando quelle dei banner
	 * @returns void
	 */
	private loadSlides(): void {
		if (!this.banners) return;
		if (this.banners?.length === 0) return;

        const slides: Slide[] = [];

		this.banners
			.filter((f) => f.bannerType === EBannerType.Offerta)
			.forEach((banner) => {
                slides.push(
					new Slide(
						banner.imgUrl,
						banner.title,
						banner.sunto,
						banner.id
					)
				);
			});

        this.slides = slides;
	}

	public getBackgroundImageFromSlide(slide: Slide): string {
		const slideImage =
			slide?.img != null ? slide.img : this.commonService.getVoidImage();
		return `url('${slideImage}')`;
	}

	public removeScript() {
		const dynamicCookiesScripNode = this._document.getElementById(
			this.#dinamicCookieScriptID
		);
		if (!dynamicCookiesScripNode) return;
		// console.log("RemoveScript", dynamicCookiesScripNode);
		const scriptNode = this.renderer2.removeChild(
			this._document.body,
			dynamicCookiesScripNode
		);
	}

	/**
	 * Isola il contenuto di elencoDati in funzione degli offset indicati
	 *	gli offset di default sono 0..100
	 * @param positionTop base inferiore per isolare il contenuto
	 * @param positionBot base superiore per isolare il contenuto
	 * @returns elencoDati o []
	 */
	public getPageData(
		positionTop: number = this.CONTENT_DISPLAY_POSITION_DEFAULT,
		positionBot: number = this.CONTENT_DISPLAY_POSITION_BOTTOM
	): PageDataGeneric[] {
		let result: PageDataGeneric[] = [];

		if (this.pageData.elencoDati != undefined) {
			result = this.pageData.elencoDati.filter(
				(item) => item.order >= positionTop && item.order <= positionBot
			);
		}


		return result;
	}

	public getStyle(style: number = 0, prefix: string = ""): string {
		prefix = prefix + style.toString();
		return prefix;
	}

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}

