import {Component, HostListener, OnInit} from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EBannerType, EOperatore, MenuData, UserService} from 'nr-common';
import {ApiService} from '../../services/api.service';
import {BannerService} from '../../services/banner.service';
import {UrlService} from '../../services/url.service';
import {WebsiteService} from '../../services/website.service';
import {Slide} from '../../shared/models/slide';
import { OperatorLink } from '../../shared/models/operatorLink';

const MIN_SCROLL_THRESHOLD = 100;

@Component({
	selector: 'nr-nav-bar',
	templateUrl: './nav-bar.component.html',
	styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
	public searchPanelState = false;
	public menuVoices: MenuData[];
	public closeResult = '';
	public solid = false;
	public onTop = false;
	public slides: Slide[] = [];
	public operatorPanelState: boolean = false;
	public currentOperator: EOperatore = EOperatore.Naar;


	constructor(
		private apiService: ApiService,
		private modalService: NgbModal,
		public bannerService: BannerService,
		public user: UserService,
		public website: WebsiteService,
		public url: UrlService
	) {}

	ngOnInit(): void {
		this.apiService.getMenu().subscribe((data: MenuData[]) => {
			this.menuVoices = data;
		});

		// Carico tutti i banner disponibili
		this.bannerService.getBanners().subscribe((data) => {
			if (data) {
				data.filter((f) => f.bannerType === EBannerType.Offerta).forEach((banner) =>
					this.slides.push(new Slide(banner.imgUrl, banner.title, banner.sunto, banner.id))
				);
			}
		});

		this.currentOperator = this.website.currentWebsite?.operatore;
	}

	toggleSearchPanel(): void {
		this.searchPanelState = !this.searchPanelState;
	}

	toggleOperatorPanel(): void {
		this.operatorPanelState = !this.operatorPanelState;
	}

	getSearchPanelState(): string {
		return this.searchPanelState ? 'box-collapse-open' : 'box-collapse-closed';
	}

	closeSearchPanel(): void {
		this.searchPanelState = false;
	}

	// getIcon(icon: string): string {
	// 	if (!icon || icon.length == 0)
	// 		return 'fa fa-question';
	// 	return icon;
	// }

	open(content) {
		this.modalService
			.open(content, {
				ariaLabelledBy: 'modal-basic-title',
				centered: true,
			})
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	getScrollClass(): string {
		return 'navbar-solid';
	}

	@HostListener('window:scroll', ['$event'])
	handleScroll() {
		const windowScroll = window.pageYOffset;
		this.solid = windowScroll >= MIN_SCROLL_THRESHOLD;
		//this.onTop = (windowScroll >= MIN_SCROLL_THRESHOLD);
	}

	getUserName() {
		return this.user.currentUser?.displayName ? this.user.currentUser?.displayName : '';
	}

	getUserId() {
		return this.user.currentUser?.id ? this.user.currentUser.id : '';
	}

	public async doLogOut() {
		const user = await this.user.logout();
		//location.href = '/';
	}

	// Restituisce il logo in HomePage con un parametro pe revitare la cache
	getDefaultLogo(): string {
		const version = new Date();
		let dateString: string =
			version.getFullYear().toString() +
			(version.getMonth() + 1).toString().padStart(2, '0') +
			version.getDate().toString().padStart(2, '0') +
			version.getHours().toString().padStart(2, '0') +
			version.getMinutes().toString().padStart(2, '0');

		return `/assets/img/NAAR-Logo-HP-White.png?v=${dateString}`;
	}
}
